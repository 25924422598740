import { createRouter, /*createWebHistory,*/createWebHashHistory } from "vue-router";

const routes = [

  {
    path: "/",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/index",
    name: "Index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Index.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),

  routes,
});

router.beforeEach((to, from, next) => {
  // // 判断有没有登录
  let token = localStorage.getItem('token')
  if (to.name == "Login" && token){
    router.push('/index')
  }
  // console.log(to.name);
  if (!token) {
    if (to.name == "Login") {
      next();
    } else {
      router.push('/')
      next();
    }
  } else {
    // router.push('/index')
    next();
  }
})

export default router;
